.container-mode {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  justify-content: space-around;
  align-items: center;
}

.mode-left ul {
  display: flex;
  list-style: none;
}

.mode-left ul li {
  margin: 0 1.5rem;
}

@media (max-width: 581px) {
  .mode-left ul li {
    margin: 0 0.5rem;
    font-size: small;
  }
}

.left-source {
  display: flex;
  align-items: center;
}

.source-icon {
  padding-right: 5px;
}

.container-icon {
  cursor: pointer;
  padding: 0.5rem 0.6rem;
  border: none;
  background: var(--gray-color);
  border-radius: 10px;
}

.mode-icon {
  color: var(--purple-smoke-color);
  font-size: 18px;
}

/* Link Githuv */
a {
  display: inline-flex;
  align-items: center;
  color: var(--white-color);
  text-decoration: none;
}

.link-decoration {
  position: relative;
  color: white;
}

.link-decoration::before,
.link-decoration::after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.link-decoration::after {
  background: white;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 300ms ease-out;
}

.icon-change {
  display: grid;
  width: 15px;
  margin-left: 10px;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  overflow-x: hidden;
}

.icon-change img {
  width: 15px;
  transform: translateX(-35px);
  transition: transform 300ms ease-out;
}

a:hover .link-decoration::after {
  transform: scaleX(1);
  transform-origin: left;
}

a:hover img {
  transform: translateX(0);
}
